import React from 'react'
import { Outlet } from 'react-router-dom'
// import Home from '../screen/Home'


const RootLayout = () => {
  return (
    <>
        <Outlet/>

        
    </>
  )
}

export default RootLayout